
*, *::before, *::after {
  box-sizing: border-box;
}

body {
  background-image: url('/public/41jmram0ukgxr16.jpg');
}

.hero {
  background: url('/public/harriet-israel.jpg') no-repeat center center;
  background-size: contain;
  background-color: rgb(220, 204, 244);
  height: 600px;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.8);
  overflow-x: hidden;
}

.App h1 {
  font-size: 3rem;
  background-color: rgb(255, 228, 95);
  color: black;
  padding: 1rem;
  margin: 0;
}

.hero h1 {
  position: absolute;
  width: 100%;
  max-width: 600px;
  bottom: 2rem;
  left: 1rem;
}
h1#title {
  position: absolute;
  width: 100%;
  right: 0;
  left: unset;
  bottom: unset;
  text-align: center;
  font-weight: 700;
  font-size: 3.25rem;
}


h1 div span.first-letter {
  position: relative;
  color: rgb(28, 71, 148);
}

h1 div span:not(.first-letter) {
    position: relative;
    opacity: 0;
    animation: move-text 0.75s forwards;
}

h2 {
  color: rgb(28, 71, 148);
  text-align: center;
  font-size: 1.75rem;
}

p {
  font-size: 1.1rem;
}


.content {
  background-color: white;
  padding: 1rem;
  margin: 2rem 0rem;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: rgb(249, 247, 252);

  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}

.slide-in-on-scroll {
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}

.slider-button {
  text-align: center;
}

.slider-button div {
  overflow: hidden;
}

.slider-button button {
  background-color: rgb(255, 228, 95);
  color: black;
  font-weight: 700;
  width: 100%;
  max-width: 400px;
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  margin: 0.5rem auto;
  display: block;
  cursor: pointer;
  transition: 0.25s ease-in-out;
  font-family: 'Ubuntu', sans-serif;
}

.slider-button button:hover {
  background-color: hsl(50, 100%, 55%);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.35);  
}

.hero-button {
  background-color: rgb(106, 53, 186);
  color: white;
  text-decoration: none;
  text-align: center;
  display: block;
  font-weight: 700;
  max-width: 100%;
  font-size: 2.5rem;
  padding: 0.5rem 0;
  border: none;
  border-radius: 0.25rem;
  margin: 0.5rem 1rem;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}

.hero-button:hover {
  background-color: rgb(83, 37, 151);
}

.twitter-button {
  background-color: rgb(0, 172, 237);
  color: white;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
  width: 100%;
  max-width: 400px;
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  margin: 0.5rem auto;
  display: block;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}

.twitter-button:hover {
  background-color: rgb(0, 150, 210);
}

.footer {
  background-color: rgb(220, 204, 244);
  padding: 1rem;
  margin-top: 4rem;
  text-align: center;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
}

.aspect-\[2\/1\] {
  aspect-ratio: 2/1;
}

@media (min-width: 400px) {
  .hero-button {
    max-width: 400px;
    margin: 0.5rem auto;
  }
}

@media (min-width: 768px) {
  .content {
    margin: 2rem auto;
    max-width: 800px;
  }
}

@keyframes move-text {
    0% { bottom: -0.2em; opacity: 1; }
    50% { bottom: 0.2em; }
    100% { bottom: 0; opacity: 1; }
}